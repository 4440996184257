import React from 'react';
import { ASSET_PREFIX } from 'utils/constants';

const FittedText = ({ text, style, imageUrl }) => {
  return (
    <div className="container">
      <img className="fittedText" src={`${ASSET_PREFIX}/images${imageUrl}`} alt={text} style={{ ...style }} />

      <style jsx>
        {`
        .container {
          width: 100%;
        }
        
        .fittedText {
          width: 100%;
        }
        `}
      </style>
    </div>
  );
};

export default FittedText;
