import { ASSET_PREFIX } from 'utils/constants';

const PrizeCategoryCardStrongerVariant = ({ categoryTitle, backgroundImage, darkenBackground, textAtBottom, href, onClick, children }) => {
  return (
    <div className="hero m--tall d--tall ms--51vw ds--26vw">
      <div className="ratio">
        <figure className="responsiveImg responsive-img hero-image">
          <img src={backgroundImage} alt=""/>
        </figure>

        <div className={`hero-content ${textAtBottom ? 'center-bottom' : 'space-between'}`}>
          <div className={`center ${textAtBottom ? 'margin-bottom' : ''}`}>
            { categoryTitle || children }
          </div>

          <div style={{ width: '100%' }}>
            <div style={{ width: '20%', margin: 'auto' }}>
              <img
                src={`${ASSET_PREFIX}/images/landing/arrow-right.png`}
                alt="Arrow right"
              />
            </div>
          </div>
        </div>
      </div>
      <a className="link hover-effect" href={href} onClick={onClick} />

      <style jsx>
        {`
          .hero {
            position: relative;
            overflow: hidden;
            text-color: #FFFFFF;
            background-color: #FFFFFF;
          }
          
          .hero.m--tall:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 152.5%;
            box-sizing: border-box;
          }
          
          .ratio {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          
          .hero .hero-image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          
          img {
            display: block;
            max-width: 100%;
          }
          
          .responsiveImg {
            position: relative;
          }
          
          figure {
            display: block;
            margin: 0;
            height: 100%;
            width: 100%;
          }
          
          .hero .hero-content {
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            color: #FFFFFF;
            z-index: 3;
            display: flex;
            flex-direction: column;
            padding: 4rem;
            pointer-events: none;
            ${darkenBackground && 'background: linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.7) 100%);'}
          }
          
          .hero .hero-content.center-bottom {
            justify-content: flex-end;
            align-items: center;
          }
          
          .hero .hero-content.space-between {
            justify-content: space-between;
            align-items: center;
          }
          
          .center {
            justify-content: flex-start;
            align-items: center;
            width: 100%;
          }
          
          .margin-bottom {
            margin-bottom: 15%;
          }
          
          .hero.ms--51vw .hero-content {
            padding: 10vw 7vw 5vw;
          }
          
          .hero .hero-content .headline {
            margin-top: 0.8rem;
            font-size: 1.3rem;
            font-weight: 800;
            letter-spacing: 0;
          }
          
          :global(.hero .link) {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: #000;
            opacity: 0;
            z-index: 1;
            transition: opacity 0.2s ease-out;
            cursor: ${href || onClick ? 'pointer' : 'default'};
          }
          
          :global(.hero:hover .link) {
            opacity: 0.3;
          }
          
          @media(min-width: 450px) {
            .hero .hero-content .headline {
              font-size: 1.7rem;
            }
          }
          
          @media(min-width: 600px) {
            .hero .hero-content .headline {
              font-size: 2.1rem;
            }        
          }
          
          @media(min-width: 1000px) {
            .hero {
              max-height: 800px;
            }
  
            .hero.ms--51vw .hero-content {
              padding: 4.5vw 3vw 2.5vw;
            }
            
            .hero .hero-content .headline {
              font-size: 2rem;
            }
          }
          
          @media(min-width: 1200px) {
            .hero .hero-content .headline {
              font-size: 2.2rem;
            }        
          }
          
          @media(min-width: 1500px) {
            .hero .hero-content .headline {
              font-size: 2.6rem;
            }
          }
  
          @media(min-width: 2200px) {
            .hero.ms--51vw .hero-content {
              padding: 3vw 3vw 2vw;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PrizeCategoryCardStrongerVariant;
