import { ASSET_PREFIX } from 'utils/constants';
import React, { useEffect, useState } from 'react';
import PrizeCategoryCardStrongerVariant
  from 'components/landing/PrizesSection/PrizeCategoryCardStrongerVariant';
import svStyles from '../StrongerVariant.module.css';
import s from './PrizesSection.module.css';
import Router from 'next/router';
import {
  eligibleForUpgradeIncentive,
  getPromoCodeForUpgradeOffer,
} from 'api/bonus';
import { getCookie } from 'utils/cookie';
import FittedText from 'components/FittedText';
import Subheading from 'components/Subheading';
import { goUpgradeOffer } from 'components/enter/upsell/goUpgrade';

export const randomPhrases = [
  'Been a while, crocodile',
  'Working hard or hardly working?',
  'What do you call a toothless bear? A gummy bear 🤭',
  "What's up, buttercup?",
  'Fun fact. Stop signs used to be yellow.',
  'Fun fact. New York was briefly called New Orange 🍊',
  'Fun fact. Some cats are actually allergic to humans.',
  "What's crackalackin'?",
  'I like that colour on you!',
  'So...the weather. Am I right?',
  "This place hasn't been the same without you.",
  "We've been completely lost without you",
];

const freemiumCards = [
  <PrizeCategoryCardStrongerVariant
    key="leaderboard"
    className="categoryCard"
    categoryTitle={
      <FittedText
        text="You're on the leaderboard!"
        imageUrl="/landing/stronger-variant/fitted-text/leaderboard.svg"
      />
    }
    href="/leaderboard"
    backgroundImage={`${ASSET_PREFIX}/images/landing/stronger-variant/refer-a-friend.jpg`}
  />,

  <PrizeCategoryCardStrongerVariant
    key="upgrade"
    className="categoryCard"
    categoryTitle={
      <FittedText
        text="Upgrade to DAYMADE Premium for our most epic perks"
        imageUrl="/landing/stronger-variant/fitted-text/upgrade-to-premium.svg"
      />
    }
    href="/account/subscriptions/upgrade"
    backgroundImage={`${ASSET_PREFIX}/images/landing/stronger-variant/upgrade-now.jpg`}
  />,

  <PrizeCategoryCardStrongerVariant
    key="pound-raffle"
    className="categoryCard"
    categoryTitle={
      <FittedText
        text="Pound Raffle - just £1 to enter"
        imageUrl="/landing/stronger-variant/fitted-text/dpr.svg"
      />
    }
    href="/token-town/pound-raffle"
    backgroundImage={`${ASSET_PREFIX}/images/landing/stronger-variant/daily-pound-raffle.jpg`}
  />,

  <PrizeCategoryCardStrongerVariant
    key="refer"
    className="categoryCard"
    categoryTitle={
      <>
        <FittedText
          text="Refer a friend and earn rewards"
          imageUrl="/landing/stronger-variant/fitted-text/refer-a-friend.svg"
        />
      </>
    }
    href="/account/referrals"
    backgroundImage={`${ASSET_PREFIX}/images/landing/refer.jpg`}
  />,
];

const litePremiumCards = [
  freemiumCards[0],

  <PrizeCategoryCardStrongerVariant
    key="token-town"
    className="categoryCard"
    href="/token-town"
    backgroundImage={`${ASSET_PREFIX}/images/token-town/hero.jpg`}
  >
    <FittedText
      text="Token Town"
      imageUrl="/landing/stronger-variant/fitted-text/token-town.svg"
    />
    <FittedText
      text="Exchange your tokens for entries, prizes & our brand new mini games!"
      imageUrl="/landing/stronger-variant/fitted-text/token-town-subheading.svg"
      style={{ marginTop: '10%' }}
    />
  </PrizeCategoryCardStrongerVariant>,

  freemiumCards[2],
  freemiumCards[3],
];



const getFreemiumCards = (showUpgradeIncentive) => {
  if (showUpgradeIncentive) {
    // Update Freemium cards to include Freemium -> Premium "1 free entry" single-use upgrade incentive
    return [
      freemiumCards[0],

      <PrizeCategoryCardStrongerVariant
        key="upgrade-50%"
        className="categoryCard"
        categoryTitle={
          <>
            <FittedText
              text="Upgrade now for 50% off DAYMADE Premium"
              imageUrl="/landing/stronger-variant/fitted-text/upgrade-now-with-offer.svg"
            />
          </>
        }
        onClick={goUpgradeOffer}
        backgroundImage={`${ASSET_PREFIX}/images/landing/stronger-variant/upgrade-now.jpg`}
      />,

      freemiumCards[2],
      freemiumCards[3],
    ];
  }

  return freemiumCards;
};

const WelcomeBackSection = ({ id, subscriberStatus, firstName }) => {
  const [randomWelcomeBackPhrase, setRandomWelcomeBackPhrase] = useState('');
  const [showUpgradeIncentive, setShowUpgradeIncentive] = useState(false);

  useEffect(() => {
    // Only choose a random phrase on the clients to prevent server/client text mismatch error.
    setRandomWelcomeBackPhrase(
      randomPhrases[Math.floor(Math.random() * randomPhrases.length)]
    );
    (async () => {
      const jwt = getCookie('jwt');
      const showUpgradeIncentive = jwt
        ? await eligibleForUpgradeIncentive(jwt)
        : null;
      setShowUpgradeIncentive(showUpgradeIncentive);
    })();
  }, []);

  if (!subscriberStatus || !firstName) return null;

  let cards = null;
  switch (subscriberStatus) {
    case 'FREEMIUM':
      cards = getFreemiumCards(showUpgradeIncentive);
      break;

    case 'LITE':
    case 'PREMIUM':
      cards = litePremiumCards;
      break;

    default:
      break;
  }

  return (
    <div
      id={id}
      className={`sectionContainer ${s.prizesSectionContainer} ${svStyles.sectionContainer}`}
    >
      <div className="paddedMaxWidthContainer" id={s.paddedMaxWidthContainer}>
        <Subheading>
          Welcome back, <strong>{firstName}</strong>
        </Subheading>

        <p className={svStyles.sectionText}>{randomWelcomeBackPhrase}</p>
      </div>

      <div className={s.grid}>{cards}</div>
    </div>
  );
};

export default WelcomeBackSection;
